import { useIsHolder } from "../../hooks/isHolder";
import { renderContentfulRichText } from "../Contentful/RichText";
import { Form } from "./Form";
import TokenGate from "./TokenGate";

const SubmitArticle: React.FC<{ data: any }> = ({ data }) => {
  const { isHolder, isWalletConnected } = useIsHolder();
  return (
    <section className="flex items-center justify-center px-4">
      <div className="bg-coinage-blue w-full rounded-xl py-8 px-[20px] tab:px-[45px] flex gap-8 justify-between flex-col tab:flex-row">
        <div className="max-w-md">
          {renderContentfulRichText(data, "white")}
        </div>
        {isHolder ? (
          <Form />
        ) : (
          <TokenGate isWalletConnected={isWalletConnected} />
        )}
      </div>
    </section>
  );
};

export default SubmitArticle;
