import { ChangeEvent, FormEvent, useState, FocusEvent } from "react";
import Success from "../../assets/CheckCircle.svg";
import Error from "../../assets/XCircle.svg";
import { Button } from "../Actions";

export const Form: React.FC = () => {
  const defaultStatus = { state: null, message: "" };

  const [status, setStatus] = useState<{
    state: null | "success" | "error" | "loading";
    message: string;
  }>(defaultStatus);

  const shouldButtonBeDisabled = () => {
    if (!status.state) return false;
    return ["loading", "error"].includes(status.state);
  };

  const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (status.state === "error") return;
    setStatus({ state: "loading", message: "" });

    //@ts-expect-error
    const url = evt.target.articleUrl.value;

    const submission = await fetch(`/api/submit-article`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ url })
    });

    const { data, error } = await submission.json();

    if (error) {
      return setStatus({
        state: "error",
        message:
          error === "Invalid URL"
            ? "Please enter a valid URL"
            : "Sorry something went wrong, please try again or email contact@trustless.media"
      });
    }
    if (data && !error) {
      //@ts-expect-error
      evt.target.articleUrl.value = "";
      return setStatus({
        state: "success",
        message: "Thanks!"
      });
    }
  };

  const handleBlur = (evt: FocusEvent<HTMLInputElement, Element>) => {
    const isValid = evt.target.validity.valid;
    if (evt.target.value === "" || !evt.target.value) {
      return setStatus(defaultStatus);
    }
    if (isValid) {
      setStatus(defaultStatus);
    } else {
      setStatus({
        state: "error",
        message: evt.target.validationMessage
      });
    }
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (status.message === "") return;
    const isValid = evt.target.validity.valid;
    if (isValid) {
      setStatus(defaultStatus);
    }
  };
  return (
    <div className="flex flex-col justify-center relative max-w-lg tab:max-w-full">
      <form noValidate onSubmit={handleSubmit} className="flex">
        <input
          className={
            "rounded-l-md px-4 py-2 border-slate-50 flex-1 focus:outline-slate-400 w-[300px] "
          }
          placeholder="Article Link"
          formNoValidate
          type="url"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          name="articleUrl"
        />
        <Button
          analyticsProperty="email subscribe"
          modifier="secondary"
          className="rounded-l-none w-auto border-0 !border-l-2"
          type="submit"
          loading={status.state === "loading"}
          disabled={shouldButtonBeDisabled()}>
          Submit
        </Button>
      </form>

      <div className="min-h-[40px] flex gap-2 text-coinage-white items-center tab:absolute tab:top-[80px]">
        {status.state && (
          <>
            {status.state === "error" && <Error width="24" />}
            {status.state === "success" && <Success width="24" />}
            <p className="text-sm">{status.message}</p>
          </>
        )}
      </div>
    </div>
  );
};
