import { useEffect, useRef } from "react";

interface TradingViewConfig {
  symbols: Array<{
    proName: string;
    title?: string;
    description?: string;
  }>;
  showSymbolLogo: boolean;
  isTransparent: boolean;
  displayMode: string;
  colorTheme: string;
  locale: string;
}

export function Ticker() {
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    script.type = "text/javascript";

    const config: TradingViewConfig = {
      symbols: [
        {
          proName: "FOREXCOM:SPXUSD",
          title: "S&P 500 Index"
        },
        {
          proName: "BITSTAMP:BTCUSD",
          title: "Bitcoin"
        },
        {
          proName: "BITSTAMP:ETHUSD",
          title: "Ethereum"
        },
        {
          proName: "COINBASE:SOLUSD",
          description: "Solana"
        },
        {
          proName: "COINBASE:SUIUSD",
          description: "Sui"
        },
        {
          proName: "COINBASE:AKTUSD",
          description: "Akash"
        },
        {
          proName: "CRYPTO:RUNEUSD",
          description: "Thorchain"
        },
        {
          proName: "COINBASE:DOGEUSD",
          description: "Dogecoin"
        }
      ],
      showSymbolLogo: false,
      isTransparent: true,
      displayMode: "adaptive",
      colorTheme: "light",
      locale: "en"
    };

    script.innerHTML = JSON.stringify(config);

    if (widgetRef.current) {
      widgetRef.current.appendChild(script);
    }

    return () => {
      if (widgetRef.current && script) {
        widgetRef.current.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container bg-coinage-w">
      <div
        ref={widgetRef}
        className="tradingview-widget-container__widget"></div>
    </div>
  );
}
