import { fetchFromContentful } from "../utils/contentful/api";
import gql from "graphql-tag";
import { PreviewWrapper } from "../components/PreviewWrapper/PreviewWrapper";
import { renderContentfulRichText } from "../components/Contentful/RichText";
import { ContentfulImage } from "../components/Contentful/Image";
import SEO from "../components/Seo/Seo";
import SubmitArticle from "../components/SubmitArticle/SubmitArticle";
import { Link } from "../components/Actions";
import { FadeText } from "../components/Animation/FadeText";
import { Video } from "../components/Video/Video";
import { generateSingleVideoSlug } from "../utils/contentful/generateSlugs";
import Play from "../assets/play.svg";
import OpenBook from "../assets/BookOpenText.svg";
import { HomepageGrid } from "../components/HomepageGrid/HomepageGrid";
import { truncateText } from "../utils/truncateText";
import cx from "classnames";
import { Ticker } from "@/components/Ticker";

const Index = ({ preview, page, banner }: any) => {
  const commentarySection = {
    title: page.commentaryArticle.title,
    description: page.commentaryArticle.commentaryDescription,
    image: page.commentaryArticle.commentaryImage,
    url: page.commentaryArticle.commentaryArticle.url,
    articleTitle:
      page.commentaryArticle.articleTitleOverride ??
      page.commentaryArticle.commentaryArticle.title
  };

  const featuredCoinageArticle =
    page.coinageContentSectionArticleCollection.items[0];
  const restOfCoinageArticles =
    page.coinageContentSectionArticleCollection.items.slice(1);

  return (
    <PreviewWrapper preview={preview}>
      <SEO />
      {banner?.showBanner && (
        <div className="bg-coinage-orange pt-4 w-full z-0 text-coinage-offWhite flex justify-center ">
          {renderContentfulRichText(banner.bannerContent, "white")}
        </div>
      )}
      <Ticker />
      <div className="bg-coinage-offWhite/60 pb-[60px]">
        <HomepageGrid articles={page.articlesCollection.items} />

        <div className="mx-[20px] max-w-[1200px] tab:mx-auto">
          <SubmitArticle data={page.submitArticleText} />
        </div>
      </div>

      <div className="flex bg-coinage-orange flex-col tab:flex-row h-[50vh] min-h-[600px]">
        <div className="h-[500px] tab:h-auto w-full tab:w-[40%] relative">
          <ContentfulImage
            layout="fill"
            className="tab:object-cover tab:w-full tab:h-full tab:object-center"
            image={commentarySection.image}
          />
        </div>
        <div className="text-coinage-offWhite px-[20px] mt-[30px] tab:w-[60%] tab:m-auto tab:ml-[75px] tab:max-w-[600px]">
          <h3 className="mono1 mb-[0]">Commentary</h3>
          <h2 className="display3 mb-[15px]">{commentarySection.title}</h2>
          <a
            className="underline flex items-center gap-2 bold underline-offset-2 flex-wrap"
            target="_blank"
            href={commentarySection.url}
            rel="noreferrer">
            <OpenBook className="inline" height={30} />
            {commentarySection.articleTitle}
          </a>
          <p className="body1 mb-[30px] mt-[10px]">
            {commentarySection.description}
          </p>
          <Link
            className="mb-[30px]"
            analyticsProperty="about"
            modifier="secondary"
            href={commentarySection.url}>
            READ THE ARTICLE
          </Link>
        </div>
      </div>
      <div className="bg-coinage-offWhite tab:pt-[45px] tab:pb-[30px]">
        <div className="bg-coinage-offWhite p-[20px] pb-[45px] max-w-[1200px] mx-auto">
          <div className="flex mb-8 tab:mb-12 items-center">
            <div>
              {renderContentfulRichText(
                page.coinageContentSection,
                "black",
                "body"
              )}
            </div>
            <Link
              className="tab:block hidden ml-auto "
              analyticsProperty="about"
              modifier="primary"
              href={`/watch`}>
              VIEW ALL
            </Link>
          </div>
          <div className="tab:flex tab:flex-row-reverse">
            <a
              className="tab:w-[60%]"
              href={generateSingleVideoSlug(
                featuredCoinageArticle.seasonGroup.slug,
                featuredCoinageArticle.urlSlug
              )}>
              <div className="rounded-[12px] overflow-hidden relative mb-[10px]">
                <ContentfulImage image={featuredCoinageArticle.image} />
                <div className="w-[42px] h-[42px] rounded-[50%] bg-coinage-white absolute bottom-2 left-2 flex justify-center">
                  <Play width="20" />
                </div>
              </div>

              <span className="body2 text-coinage-body">
                {featuredCoinageArticle?.category?.categoryTitle}
              </span>

              <h4 className="subhead1 mb-2 mt-1">
                {featuredCoinageArticle.articleTitle}
              </h4>
              <p className="text-coinage-body mb-[45px]">
                {truncateText(featuredCoinageArticle.articleDescription, 200)}
              </p>
            </a>
            <div className="tab:w-[40%] tab:mr-[30px]">
              {restOfCoinageArticles.map((article: any, i: number) => {
                return (
                  <a
                    className={cx("flex flex-row py-8 items-center", {
                      "pt-0": i === 0,
                      "border-t-[1px] border-coinage-stroke ": i !== 0
                    })}
                    key={i}
                    href={generateSingleVideoSlug(
                      article.seasonGroup.slug,
                      article.urlSlug
                    )}>
                    <div className="rounded-[12px] overflow-hidden relative mb-[10px] w-1/2 h-fit">
                      <ContentfulImage
                        className="rounded-[12px]"
                        image={article.image}
                      />
                      <div className="w-[32px] h-[32px] rounded-[50%] bg-coinage-white absolute bottom-2 left-2 flex justify-center">
                        <Play width="10" />
                      </div>
                    </div>

                    <div className="w-1/2 ml-[15px]">
                      <span className="body2 text-coinage-body">
                        {article?.category?.categoryTitle}
                      </span>

                      <h4 className="subhead3 hidden tab:block mb-2 mt-1">
                        {article.articleTitle}
                      </h4>
                      <h4 className="subhead2 block tab:hidden mb-2 mt-1">
                        {article.articleTitle}
                      </h4>
                      <p className="text-coinage-body mb-[45px] tab:mb-[15px] tab:truncate">
                        {truncateText(
                          article.shortArticleDescription ??
                            article.articleDescription,
                          120
                        )}
                      </p>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>

          <Link
            className="mt-[30px] tab:hidden"
            analyticsProperty="about"
            modifier="primary"
            href={`/watch`}>
            VIEW ALL
          </Link>
        </div>
      </div>
      <div className="bg-coinage-orange text-coinage-offWhite">
        <div className="content-wrapper">
          <div className="flex flex-col tab:flex-row py-[64px] tab:pt-[120px] tab:pb-[76px] tab:items-center px-4">
            <FadeText
              textStyle="display2"
              className="text-left tab:max-w-[18ch]">
              {page.aboutCoinageSection.title}
            </FadeText>

            <div className="max-w-md mt-4 tab:mt-0">
              {renderContentfulRichText(
                page.aboutCoinageSection.description,
                "offWhite"
              )}

              <Link
                className=""
                analyticsProperty="about"
                modifier="primary"
                href={`/${page.aboutCoinageSection.buttonSlug}`}>
                {page.aboutCoinageSection.buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="video-wrapper pb-12">
        <div className="flex content-wrapper m-4">
          <Video
            className="max-w-[1000px]"
            contentfulImagesrc={page.aboutCoinageSection.image}
          />
        </div>
      </div>
    </PreviewWrapper>
  );
};

export const getStaticProps = async ({ preview = false }: any) => {
  const pageQuery = gql`
    query ContentHomePage($preview: Boolean) {
      homepageBanner(id: "z0xy3nAzksmUmSmVVOV5A", preview: $preview) {
        showBanner
        bannerContent {
          json
        }
      }
      contentHomePageCollection(
        limit: 1
        where: { setAsHomepage_contains: "True" }
        preview: $preview
      ) {
        items {
          internalPageTitleName
          articlesCollection {
            items {
              __typename
              ... on AggregatedArticle {
                title
                description
                url
              }
              ... on VideoArticle {
                articleTitle
                articleDescription
                shortArticleDescription
                urlSlug
                seasonGroup {
                  slug
                }
                image {
                  width
                  height
                  url
                  description
                }
              }
            }
          }
          coinageContentSectionArticleCollection {
            items {
              articleTitle
              articleDescription
              shortArticleDescription
              urlSlug
              category {
                categoryTitle
              }
              seasonGroup {
                slug
              }
              video {
                videoUpload
              }
              image {
                width
                url
                height
                description
              }
            }
          }
          submitArticleText {
            json
            links {
              assets {
                block {
                  description
                  width
                  height
                  url
                  sys {
                    id
                  }
                }
              }
            }
          }
          coinageContentSection {
            json
            links {
              assets {
                block {
                  description
                  width
                  height
                  url
                  sys {
                    id
                  }
                }
              }
            }
          }
          commentaryArticle {
            title
            articleTitleOverride
            commentaryImage {
              description
              url
              width
              height
            }
            commentaryDescription
            commentaryArticle {
              url
              title
            }
          }
          aboutCoinageSection {
            title
            description {
              json
              links {
                assets {
                  block {
                    description
                    width
                    height
                    url
                    sys {
                      id
                    }
                  }
                }
              }
            }
            image {
              url
              width
              height
              description
            }
            buttonText
            buttonSlug
          }
        }
      }
    }
  `;

  const { data, errors } = await fetchFromContentful({
    query: pageQuery,
    preview
  });

  if (errors) {
    console.error(errors);
  }

  const page = data.contentHomePageCollection.items[0];

  return {
    props: { page, preview, banner: data.homepageBanner },
    revalidate: 300
  };
};

export default Index;
