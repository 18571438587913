import LockKey from "../../assets/LockKey.svg";
import { ConnectButton, Link } from "../Actions";

const TokenGate: React.FC<{ isWalletConnected: boolean }> = ({
  isWalletConnected
}) => {
  return (
    <section className="flex items-center w-full">
      <div className="border-coinage-offWhite border-[1px] rounded-lg text-coinage-offWhite flex gap-4 p-6 items-center justify-between h-[max-content] w-full flex-wrap gap-x-12">
        <div className="flex gap-4">
          <LockKey className="w-6 h-6" />
          <span className="body1">Submit with membership pass</span>
        </div>
        {isWalletConnected ? (
          <Link
            className="ml-auto"
            href="/about#mint"
            modifier="secondary"
            analyticsProperty="Mint">
            Mint
          </Link>
        ) : (
          <ConnectButton className="ml-auto" modifier="secondary" />
        )}
      </div>
    </section>
  );
};

export default TokenGate;
