import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS, Node } from "@contentful/rich-text-types";
import { ReactNode } from "react";
import { ContentfulImage } from "./Image";

export const renderContentfulRichText = (
  content: any,
  textColor = "black",
  bodyTextColor?: string
) => {
  const options = {
    renderMark: {
      [MARKS.CODE]: (code: any) => (
        <div
          className="flex items-center flex-col"
          dangerouslySetInnerHTML={{
            __html: code
          }}
        />
      )
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Node, children: ReactNode) => (
        <p className={`body2 mb-4 text-coinage-${bodyTextColor ?? textColor}`}>
          {children}
        </p>
      ),

      // changed this to display2
      [BLOCKS.HEADING_1]: (node: Node, children: ReactNode) => (
        <h1 className={`display2 mb-4 text-coinage-${textColor}`}>
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (node: Node, children: ReactNode) => (
        <h2 className={`subhead1 text-coinage-${textColor} mb-4`}>
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (node: Node, children: ReactNode) => (
        <h3 className={`subhead2 text-coinage-${textColor} mb-4`}>
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (node: Node, children: ReactNode) => (
        <h4 className={`subhead3 text-coinage-${textColor}`}>{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node: Node, children: ReactNode) => (
        <h5 className={`subhead3 text-coinage-${textColor}`}>{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node: Node, children: ReactNode) => (
        <h6 className={`text-coinage-${textColor}`}>{children}</h6>
      ),
      [BLOCKS.OL_LIST]: (node: Node, children: ReactNode) => (
        <ol className={`text-coinage-${textColor} list-decimal ml-[18px]`}>
          {children}
        </ol>
      ),
      [BLOCKS.UL_LIST]: (node: Node, children: ReactNode) => (
        <ul className="text-coinage-black list-disc ml-[18px]">{children}</ul>
      ),
      [BLOCKS.QUOTE]: (node: Node, children: ReactNode) => (
        <blockquote className="relative mt-12 blockquote border-l-4 border-coinage-orange pl-4">
          {children}
        </blockquote>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
        const img = content.links.assets.block.find(
          (i: any) => i.sys.id === node.data.target.sys.id
        );
        return (
          <figure className="my-12">
            <div className="contentful-image-wrapper rounded-xl p-6 pl-0">
              <ContentfulImage image={img} className="rounded-xl" />
            </div>
            <figcaption className="mt-2 text-coinage-body caption">
              {img.description}
            </figcaption>
          </figure>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node: Node, children: ReactNode) => {
        const block = content.links.entries.block.find(
          (i: any) => i.sys.id === node.data.target.sys.id
        );

        if (!block) return null;

        if (block.__typename === "CodeEmbed") {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: block.code
              }}
            />
          );
        }
      },
      [INLINES.HYPERLINK]: (node: Node, children: ReactNode) => {
        return (
          <a
            className="underline"
            target={node.data.uri.includes("http") && "_blank"}
            rel="noopener noreferrer"
            href={node.data.uri}>
            {children}
          </a>
        );
      }
    }
  };

  return documentToReactComponents(content.json, options);
};
