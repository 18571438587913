import Link from "next/link";
import { generateSingleVideoSlug } from "../../utils/contentful/generateSlugs";
import { ContentfulImage } from "../Contentful/Image";
import BugWordmark from "../../assets/BugWordmark.svg";
import { truncateText } from "../../utils/truncateText";

export const HomepageGrid: React.FC<{
  articles: any[];
}> = ({ articles }) => {
  return (
    <div className="max-w-[1200px] mx-auto tab:grid tab:grid-cols-4 tab:pt-[25px] tab:gap-[40px] tab:mx-[20px] tab:xl:mx-auto mb-[45px]">
      {articles.map((article, i) => {
        if (article.__typename === "VideoArticle" && i === 0) {
          return (
            <div
              key={i}
              className="mb-[30px] col-start-2 col-span-2 row-start-1 row-span-2 tab:mb-0">
              <Link
                href={generateSingleVideoSlug(
                  article.seasonGroup.slug,
                  article.urlSlug
                )}>
                <div className="tab:rounded-[12px] overflow-hidden">
                  <ContentfulImage image={article.image} />
                </div>
                <h3 className="mx-[20px] body2 mt-[15px] tab:mx-0">
                  <span className="inline">
                    <BugWordmark className="inline" height={30} />
                  </span>{" "}
                  Coinage
                </h3>

                <h2 className="subhead1 mx-[20px] tab:mx-0 mb-[5px]">
                  {article.articleTitle}
                </h2>
                <p className="body2 mx-[20px] tab:mx-0">
                  {truncateText(article.articleDescription, 200)}
                </p>
              </Link>
            </div>
          );
        }
        if (article.__typename === "VideoArticle") {
          return (
            <div key={i} className="mb-[30px] mx-[20px] tab:mx-0 tab:mb-0">
              <Link
                href={generateSingleVideoSlug(
                  article.seasonGroup.slug,
                  article.urlSlug
                )}>
                <div className="rounded-[12px] overflow-hidden">
                  <ContentfulImage image={article.image} />
                </div>
                <h3 className="body2 mt-[15px]">
                  <span className="inline">
                    <BugWordmark className="inline" height={30} />
                  </span>{" "}
                  Coinage
                </h3>

                <h2 className="subhead2 mb-[7px]">{article.articleTitle}</h2>
                <p className="body2">
                  {truncateText(
                    article.shortArticleDescription ??
                      article.articleDescription
                  )}
                </p>
              </Link>
            </div>
          );
        } else if (article.__typename === "AggregatedArticle") {
          return (
            <div
              key={i}
              className="mt-[45px] border-t-2 border-coinage-orange pt-[10px] mx-[20px] tab:mx-0 tab:mt-0">
              <Link href={article.url}>
                <h2 className="subhead2">{article.title}</h2>
                <p className="body2">{truncateText(article.description, 80)}</p>
              </Link>
            </div>
          );
        }
      })}
    </div>
  );
};
